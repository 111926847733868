import { useState } from 'react'
import { myCourses, getModules } from 'API/course'
import Select from 'react-select'
import { useEffect } from 'react'
import { PageProps } from 'gatsby'
import React from 'react'
import { toast } from 'react-toastify'
import { Button } from 'react-bootstrap'
import { useAppSelector } from 'redux/hooks'
import { CourseType } from 'types/CourseAdditionMetaData'
import { CustomModal } from '../../../../../components/common/useModal'
import axios from 'axios'
import { handleApi } from 'API/handleApiCall'
import { getUrl } from 'API/getUrl'
import { getDefaultOptions } from 'API/auth'
import { RetType } from 'pages/user/courses/CourseComponent'
import { PromiseType } from 'utility-types'
const resourceDates = [
	{ text: 'Visible From:', name: 'visible_from' },
	{ text: 'Start Time:', name: 'start' },
	{ text: 'End Time', name: 'end' },
]

const External: React.FC<PageProps> = (props: PageProps) => {
	const course = props.params.id

	const [data, setData] = useState({
		course_id: course,
		import_from_course_id: '',
		course_duration: 3,
	})
	const importModule = async () => {
		console.log(data)
		const result = await handleApi<any>(axios.post(getUrl('asset/import/modules_from_course'), data, getDefaultOptions()))
		if (result) {
			console.log('result')
			console.log(result)
			toast('Modules Transferred', { type: 'success' })
		}
	}
	const [data1, setData1] = useState({
		course_id: course,
		import_from_course: '',
		module_duration: 3,
		module_from: '',
		module_into: '',
	})
	const importactivities = async () => {
		console.log(data1)
		const result = await handleApi<any>(axios.post(getUrl('asset/import/activities_into_module'), data1, getDefaultOptions()))
		if (result) {
			toast('Activities Transferred', { type: 'success' })
			console.log(result)
		}
	}
	const [courses, setCourses] = useState<RetType>({
		studying: [
			// {
			// 	course: online,
			// },
		],
		teaching: [],
	})
	useEffect(() => {
		const run = async () => {
			const courses = await myCourses()
			setCourses(courses)
		}
		run()
	}, [])

	const [teachingArr, setTeachingArr] = useState<
		{
			course: CourseType
		}[]
	>([])
	const [moduleList, setModuleList] = useState<PromiseType<ReturnType<typeof getModules>>>()
	const [moduleList_into, setModuleListInto] = useState<PromiseType<ReturnType<typeof getModules>>>()
	const numbers = [3, 6, 12, 24]
	const search = useAppSelector((state) => state.ApplicationReducer.search)
	React.useEffect(() => {
		if (courses) {
			const tempArr = courses?.teaching
			console.log(tempArr)
			if (search === '') {
				setTeachingArr(tempArr)
			} else {
				setTeachingArr(tempArr.filter((elem) => search.split('=')?.[1]?.toUpperCase() !== elem.course?.course_tag?.toUpperCase()))
			}
		}
	}, [courses, search])
	const ref = React.useRef<HTMLButtonElement | null>(null)
	return (
		<>
			<>
				<CustomModal header='Imports'>
					<div className='row'>
						<div className='col-lg-6 col-sm-12'>
							Import course from
							<Select
								options={teachingArr
									.filter((ele) => {
										return ele.course.type_of_course == 'Online'
									})
									.map((elem) => ({
										label: elem.course.name,
										id: elem.course.uid,
									}))}
								onChange={(e) => {
									if (e && e?.id) {
										// setCourse_id(e.id)
										setData((data) => ({ ...data, import_from_course_id: e.id }))
									}
									// console.log(e?.id)
								}}
							/>
						</div>
						<div className='col-6'>
							<div>Duration of the course</div>
							<Select
								options={numbers.map((elem) => ({
									label: elem,
								}))}
								onChange={(e) => {
									if (e) {
										setData((data) => ({ ...data, course_duration: e.label }))
									}
									console.log(e?.label)
								}}
							/>
						</div>
						<div className='col-6'>
							<Button className='fa fa-download px-2 mt-2 mb-4' onClick={importModule}>
								Get modules
							</Button>
						</div>

						{/* <div className='container table-responsive py-5'>
							<table className='table table-bordered table-hover'>
								<thead className='thead-dark'>
									<tr>
										<th scope='col'>#</th>
										<th scope='col'>Success</th>
										<th scope='col'>Failed</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th scope='row'>1</th>
										<td>Larry</td>
										<td>the Bird</td>
									</tr>
								</tbody>
							</table>
						</div> */}
					</div>
					<div>
						<div className='row'>
							<div className='col-lg-6 col-sm-12'>
								Import from course{' '}
								<Select
									options={teachingArr
										.filter((ele) => {
											return ele.course.type_of_course == 'Online'
										})
										.map((elem) => ({
											label: elem.course.name,
											id: elem.course.uid,
										}))}
									onChange={async (e) => {
										if (e && e?.id) {
											// setCourse_id(e.id)
											setData1((data1) => ({ ...data1, import_from_course: e.id }))
											const modules_from = await getModules(e.id)
											if (modules_from) {
												setModuleList(modules_from)
											}
											const modules_into = await getModules(course)
											if (modules_into) {
												setModuleListInto(modules_into)
											}
										}
										// console.log(e?.id)
									}}
								/>
							</div>
							<div className='col-lg-6 col-sm-12'>
								Transfer from{' '}
								<Select
									options={moduleList?.map((elem) => ({
										label: elem.name,
										id: elem.uid,
									}))}
									onChange={(e) => {
										if (e && e?.id) {
											// setCourse_id(e.id)
											setData1((data1) => ({ ...data1, module_from: e.id }))
										}
										// console.log(e?.id)
									}}
								/>
							</div>
							<div className='col-lg-6 col-sm-12'>
								Transfer into{' '}
								<Select
									options={moduleList_into?.map((elem) => ({
										label: elem.name,
										id: elem.uid,
									}))}
									onChange={(e) => {
										if (e && e?.id) {
											// setCourse_id(e.id)
											setData1((data1) => ({ ...data1, module_into: e.id }))
										}
										// console.log(e?.id)
									}}
								/>
							</div>
							{/* <div className='mt-2'>Activity Duration</div> */}
							<div className='col-6'>
								<div>Duration of the Activities</div>
								<Select
									options={numbers.map((elem) => ({
										label: elem,
									}))}
									onChange={(e) => {
										if (e) {
											setData1((data1) => ({ ...data1, module_duration: e.label }))
										}
										console.log(e?.label)
									}}
								/>
							</div>
							<div className='col-6'>
								<Button className='fa fa-download px-2 mt-2' onClick={importactivities}>
									Get activities
								</Button>
							</div>
						</div>
					</div>
				</CustomModal>
			</>
		</>
	)
}

export default External
